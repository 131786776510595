import { Label, RadioGroup } from '@components';
import { NormalizedVariant } from '@ts/product';
import { formatCurrency } from '@utils/shopify';
import { LOCALE_CODES } from '@constants';
import styles from './GiftCardAmountSelector.module.scss';

type GiftCardAmountSelectorProps = {
	prices: Array<{ amount: number }>;
	onChange: (price: number) => void;
	selectedVariant: NormalizedVariant;
	locale: string;
};

const GiftCardAmountSelector = ({ onChange, prices, locale, selectedVariant }: GiftCardAmountSelectorProps) => {
	const showCurr = locale === LOCALE_CODES.AU || locale === LOCALE_CODES.CA;
	const defaultPrice = selectedVariant?.price?.amount || prices[0].amount;
	return (
		<RadioGroup value={defaultPrice.toString()} className={styles.container}>
			{prices.map((price, index) => {
				return (
					<RadioGroup.Option
						key={`${price.amount}-${index}`}
						option={price.amount.toString()}
						callback={update => onChange(parseInt(update))}
					>
						<Label input={price.amount}>{formatCurrency({ ...price, locale }, showCurr)}</Label>
					</RadioGroup.Option>
				);
			})}
		</RadioGroup>
	);
};

export default GiftCardAmountSelector;
