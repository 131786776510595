import { useState, useEffect } from 'react';
import { Flex, Img } from '@components';
import { NormalizedVariant } from '@ts/product';
import styles from './GiftCardSelector.module.scss';

type GiftCardSelectorProps = {
	variants: Array<NormalizedVariant>;
	selectedVariant: NormalizedVariant;
	onChange: (variant: NormalizedVariant) => void;
	prices: Array<{ amount: number }>;
};

const GiftCardSelector = ({ variants, selectedVariant, onChange, prices }: GiftCardSelectorProps) => {
	const [displayVariants, setDisplayVariants] = useState([]);

	useEffect(() => {
		const valuesCount = prices.length;
		const variantCount = variants.length;
		const newCardVariants = variants
			.slice(0, variantCount / valuesCount)
			.map(v => ({ ...v, image: { ...v.image, src: v.image.url } }));

		setDisplayVariants(newCardVariants);
	}, [prices.length, variants]);

	return (
		<Flex className={styles.container}>
			{displayVariants.length
				? displayVariants.map((variant, index) => (
						<div
							key={`${variant.id}-${index}`}
							className={`${styles.imageOption} ${
								selectedVariant?.image.id === variant.image.id ? styles.selected : ''
							}`}
						>
							<Img
								className={styles.swatch}
								alt={variant.option}
								src={variant.image.src}
								onClick={() => {
									onChange(variant);
								}}
							/>
						</div>
					))
				: ''}
		</Flex>
	);
};

export default GiftCardSelector;
