import React, { createContext, ReactNode, useContext, useRef } from 'react';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import cn from 'classnames';
import { ComponentProps } from '@ts/components';
import styles from './RadioGroup.module.scss';

type RadioOptionProps = ComponentProps<HTMLDivElement> & {
	option: string;
	callback: (option: string) => void;
	disabled?: boolean;
	checked?: boolean;
};

type RadioGroupProps = RadioGroupPrimitive.RadioGroupProps & {
	children: ReactNode;
};

const RadioContext = createContext('');

const RadioOption = ({ option, children, callback, disabled = false, checked = false, ...rest }: RadioOptionProps) => {
	const value = useContext(RadioContext);
	const ref = useRef<HTMLButtonElement>(null);

	return (
		<div
			key={`radio-option-${option}`}
			className={cn(styles.option, { [styles.disabled]: disabled })}
			onClick={() => {
				callback(option);
				ref.current?.click();
			}}
			{...rest}
		>
			<RadioGroupPrimitive.Item
				aria-checked={option === value}
				checked={option === value || checked}
				value={option}
				id={option}
				className={styles.radioOuter}
				ref={ref}
			>
				<RadioGroupPrimitive.RadioGroupIndicator forceMount className={styles.radioInner}>
					<div className={styles.radioDot} />
				</RadioGroupPrimitive.RadioGroupIndicator>
			</RadioGroupPrimitive.Item>
			{children}
		</div>
	);
};

const RadioGroup = ({ value, children, className }: RadioGroupProps) => {
	return (
		<RadioGroupPrimitive.Root className={className ?? styles.container} defaultValue={value}>
			<RadioContext.Provider value={value}>{children}</RadioContext.Provider>
		</RadioGroupPrimitive.Root>
	);
};

RadioGroup.Option = RadioOption;

export default RadioGroup;
